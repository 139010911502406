import React from 'react'
import Helmet from 'react-helmet'

import Navbar from './Navbar'
import './scss/layout.scss'
import './scss/app.scss'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet>
        <title>Benjamin Caron - BAFTA nominated English television director</title>
        <link rel='apple-touch-icon' sizes='180x180' href={'/images/apple-touch-icon.png'}/>
        <link rel='icon' type='image/png' sizes='32x32' href={'/images/favicon-32x32.png'}/>
        <link rel='icon' type='image/png' sizes='16x16' href={'/images/favicon-16x16.png'}/>
        <link rel='manifest' href={'/images/site.webmanifest'}></link>
    </Helmet>
    <section>{children}</section>
    <footer>
    <div class='grid-container'  data-sal="slide-up" data-sal-duration="1000">
    	<div class='grid-x align-center'>
    		<div class='cell small-12'>
    			<p>© {new Date().getFullYear()} Benjamin Caron. All Rights Reserved. Site Design <a href="stirtingale" target="_blank" title="creative web design">Stirtingale</a></p>
    		</div>
    	</div>
    </div>
    </footer>
  </div>
)

export default TemplateWrapper
